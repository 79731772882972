import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import ExternalLink from '../../components/ExternalLink';
import LeadParagraph from '../../components/LeadParagraph';
import Image from '../../components/Image';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "foundation-overview",
      "style": {
        "position": "relative"
      }
    }}>{`Foundation overview`}<a parentName="h1" {...{
        "href": "#foundation-overview",
        "aria-label": "foundation overview permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
  Foundation is a guide for applying the visual guidelines to digital experiences.
    </LeadParagraph>
    <p>{`Helsinki Design System foundations describe how the `}<ExternalLink href="https://brand.hel.fi/en/" mdxType="ExternalLink">{`Helsinki Visual Identity`}</ExternalLink>{` is applied to digital world. Foundation varies from detailed practical guides to broader principles that needs to be addressed when designing and developing a service.`}</p>
    <p><strong parentName="p">{`Design tokens`}</strong>{` are the smallest building blocks of the design system. They are used across all Helsinki products to ensure matching visual identity.`}</p>
    <p><strong parentName="p">{`Visual assets`}</strong>{` define the common set of graphical elements used in digital services.`}</p>
    <p><strong parentName="p">{`Guidelines`}</strong>{` give answers and suggestions to common situations we come across in digital products. They describe the Helsinki way of solving these issues.`}</p>
    <Image src="/images/foundation/foundation.svg" alt="The HDS Foundation page image with HDS and HKI texts and design system icons" style={{
      "width": "100%",
      "maxWidth": "650px",
      "margin": "var(--spacing-layout-2-xs) 0"
    }} viewable mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      